import React, { useEffect, useState } from "react";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import { host, v } from "../../../config";

const LandingPage = () => {
  const [loading, setLoading] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const [featuredJobs, setFeaturedJobs] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincodes, setPincodes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [filteredFeaturedJobs, setFilteredFeaturedJobs] = useState([]);

  // function getJobs() {
  //   setLoading(true);
  //   fetch(`${host}/api/${v}/visitor/job`, {
  //     method: "GET",
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       AllJobs(data.jobs);
  //       FeaturedJobs(data.jobs);
  //       FilteredFeaturedJobs(data.jobs);
  //       let country = [...new Set(data.jobs.map((item) => item.country))];
  //       Countries(country);
  //       let city = [...new Set(data.jobs.map((item) => item.city))];
  //       Cities(city);
  //       let pincode = [...new Set(data.jobs.map((item) => item.pincode))];
  //       Pincodes(pincode);
  //     })
  //     .catch((error) => {
  //       // handleClick({ vertical: "top", horizontal: "right" });
  //       console.error("Error during fetch:", error);
  //     })
  //     .finally(() => {
  //       // navigate("/register/steps/welcome");
  //       Loading(false);
  //     });
  // }

  const jobs = [
    {
      id: 1,
      company: "Linear Company",
      companyLogo:
        "https://logodownload.org/wp-content/uploads/2015/04/whatsapp-logo-7.png",
      title: "Drawing Teacher",
      location: "Mumbai",
      type: "Full time",
      salary: "$10-77k",
      posted: "29 min ago",
      description:
        "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },

    {
      id: 2,
      company: "Acnisdi Company",
      companyLogo:
        "https://www.freepnglogos.com/uploads/company-logo-png/company-logo-telestream-press-kit-12.png",
      title: "Software Trainer",
      location: "Chennai",
      type: "Full time",
      salary: "$30-55k",
      posted: "2 days ago",
      description:
        "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },

    {
      id: 3,
      company: "Acnisdi Company",
      companyLogo:
        "https://i.pinimg.com/originals/69/e0/64/69e0645c1a01e38f6092dac2703e760d.png",
      title: "Front-end Developer",
      location: "Kerala",
      type: "Full time",
      salary: "$50-55k",
      posted: "1 week ago",
      description:
        "lorecd lojndvd sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },

    {
      id: 4,
      company: "Techz Company",
      companyLogo:
        "https://www.freepnglogos.com/uploads/company-logo-png/bmw-car-company-logo-png-transparent-image-3.png",
      title: "Back-end Developer",
      location: "Bangalore",
      type: "Full time",
      salary: "$10-45k",
      posted: "3 week ago",
      description:
        "lorecd lojndvd sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];

  // useEffect(() => getJobs(), []);

  return (
    <div className="w-full min-h-screen bg-white">
      
      <Navbar />
      
      <div className="max-w-7xl mx-auto">
        {/* Hero Section */}
        <div className="bg-[#393f47] w-full">
          <div className="flex flex-col lg:flex-row items-center justify-between py-12 lg:py-20 px-4 lg:px-8 gap-8">
            <div className="w-full lg:w-1/2 space-y-6">
              <h1 className="text-3xl lg:text-5xl font-bold text-white">
                Find Teaching Jobs in India!
              </h1>
              <img
                src="https://i.ibb.co/MRZCwrw/Group.png"
                alt="icon"
                className="w-full max-w-sm"
              />
              <p className="text-gray-300 text-lg leading-relaxed">
                Search hundreds of teaching jobs, including opportunities with
                international schools, government programs, universities and
                language colleges in INDIA.
              </p>
            </div>
            <img
              src="/landing_banner.png"
              alt="banner"
              className="w-full lg:w-1/2 rounded-lg object-cover"
            />
          </div>
        </div>

        {/* What is TutorPlatter Section */}
        <div className="py-16 px-4">
          <h2 className="text-3xl lg:text-4xl font-bold text-center mb-8">
            What is TutorPlatter?
          </h2>
          <p className="text-gray-600 text-center max-w-3xl mx-auto mb-12">
            TutorPlatter is dedicated to bridging the gap between students and
            top notch tutors worldwide.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* For Tutors */}
            <div className="border border-gray-200 rounded-lg p-6 hover:shadow-lg transition-shadow">
              <img
                src="https://i.ibb.co/fvJZ1Gg/Job.png"
                alt="tutor"
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-xl font-semibold mb-4">For Tutors</h3>
              <p className="text-gray-600">
                Become part of TutorPlatter's vibrant and supportive network of
                educators.
              </p>
            </div>

            {/* For Educational Institutes */}
            <div className="border border-gray-200 rounded-lg p-6 hover:shadow-lg transition-shadow">
              <img
                src="https://i.ibb.co/WkvRW2W/Deal.png"
                alt="institute"
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-xl font-semibold mb-4">
                For Educational Institutes
              </h3>
              <p className="text-gray-600">
                Partner with TutorPlatter to enhance your institute's
                educational offerings.
              </p>
            </div>

            {/* For Students */}
            <div className="border border-gray-200 rounded-lg p-6 hover:shadow-lg transition-shadow">
              <img
                src="https://i.ibb.co/60NF6KQ/Student.png"
                alt="student"
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-xl font-semibold mb-4">For Students</h3>
              <p className="text-gray-600">
                Discover the ideal tutor who meets your specific academic needs
                with ease.
              </p>
            </div>
          </div>
        </div>

        {/* First Promotional Section */}
        <div className="bg-[url('https://i.ibb.co/z8bpznY/Rectangle-2742.png')] bg-cover bg-center w-full">
          <div className="flex flex-col lg:flex-row items-center justify-between py-12 lg:py-16 px-4 lg:px-16 gap-8">
            <img
              src="https://i.ibb.co/BwpK5GM/Desktop-1.png"
              alt="Find Your Tutor"
              className="w-full lg:w-1/2 object-contain order-2 lg:order-1"
            />
            <div className="w-full lg:w-1/2 text-white space-y-6 order-1 lg:order-2">
              <h2 className="text-3xl lg:text-4xl text-white font-bold">
                Find Your Ideal Tutor
              </h2>
              <p className="text-xl text-white">Get Your Tutor Today!</p>
              <button className="bg-white text-blue-600 px-8 py-3 text-lg font-semibold rounded hover:bg-gray-100 transition-colors">
                Sign Up For Free
              </button>
            </div>
          </div>
        </div>

        {/* Teaching Career Section */}
        <div className="py-16 px-4">
          <h2 className="text-3xl lg:text-4xl font-bold text-center mb-8">
            Do You Want a Teaching Career?
          </h2>
          <p className="text-gray-600 text-center max-w-3xl mx-auto mb-12">
            Take your teaching skills overseas. Improve your resume. Whatever
            your path, all of our programs are developed with one thing in mind:
            the power of a great teacher.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {jobs.map((job) => (
              <div
                key={job.id}
                className="border border-gray-200 rounded-lg p-6 hover:shadow-lg transition-shadow"
              >
                <div className="flex flex-col sm:flex-row gap-4">
                  <img
                    src={job.companyLogo}
                    alt={job.company}
                    className="w-12 h-12 rounded-full"
                  />
                  <div className="flex-1">
                    <p className="text-gray-600">{job.company}</p>
                    <h3 className="text-xl font-semibold">{job.title}</h3>
                    <div className="flex flex-wrap gap-4 mt-2 text-sm text-gray-500">
                      <p className="flex items-center gap-1">
                        <i className="bi bi-briefcase"></i> {job.type}
                      </p>
                      <p className="flex items-center gap-1">
                        <i className="bi bi-currency-dollar"></i> {job.salary}
                      </p>
                      <p className="flex items-center gap-1">
                        <i className="bi bi-geo-alt"></i> {job.location}
                      </p>
                      <p className="flex items-center gap-1">
                        <i className="bi bi-clock"></i> {job.posted}
                      </p>
                    </div>
                    <p className="mt-4 text-gray-600">{job.description}</p>
                  </div>
                  <button className="text-gray-400 hover:text-blue-500 transition-colors">
                    <i className="bi bi-bookmark"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Second Promotional Section */}
        <div className="bg-[url('https://i.ibb.co/z8bpznY/Rectangle-2742.png')] bg-cover bg-center w-full">
          <div className="flex flex-col lg:flex-row items-center justify-between py-12 lg:py-16 px-4 lg:px-16 gap-8">
            <div className="w-full lg:w-1/2 text-white space-y-6">
              <h2 className="text-3xl lg:text-4xl text-white font-bold">
                Join Our Network of Expert Tutors
              </h2>
              <p className="text-xl text-white">
                Create Your Tutor Profile Today!
              </p>
              <button className="bg-white text-blue-600 px-8 py-3 text-lg font-semibold rounded hover:bg-gray-100 transition-colors">
                Sign Up For Free
              </button>
            </div>
            <img
              src="https://i.ibb.co/Yd3KPhP/Desktop.png"
              alt="Join Network"
              className="w-full lg:w-1/2 object-contain"
            />
          </div>
        </div>

        {/* Footer */}
        <footer className="bg-[#202430] text-white">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-8 lg:p-16">
            <div>
              <img
                src="https://usercentereddesign.co.in/tutor/images/logo.png"
                alt="Tutor Platter"
                className="h-16 mb-4"
              />
              <p className="text-gray-400">
                Great platform for job seekers passionate about startups.
              </p>
            </div>

            <div>
              <h4 className="text-lg font-semibold mb-4">About</h4>
              <div className="flex flex-col space-y-2">
                <a
                  href="#"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  About Us
                </a>
                <a
                  href="#"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  Terms
                </a>
                <a
                  href="#"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  Privacy Policy
                </a>
              </div>
            </div>

            <div>
              <h4 className="text-lg font-semibold mb-4">
                Get job notifications
              </h4>
              <p className="text-gray-400 mb-4">
                The latest job news, articles, sent to your inbox weekly.
              </p>
              <div className="flex flex-col sm:flex-row gap-2">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="px-4 py-2 rounded-lg flex-1 text-gray-900"
                />
                <button className="px-6 py-2 bg-[#80d95b] text-white rounded-lg hover:bg-green-600 transition-colors">
                  Subscribe
                </button>
              </div>
            </div>
          </div>

          <div className="border-t border-gray-800">
            <div className="flex flex-col sm:flex-row justify-between items-center gap-4 p-8 lg:px-16">
              <p className="text-gray-400">
                &copy; 2024 TUTOR PLATTER. All rights reserved.
              </p>
              <div className="flex gap-4">
                <a
                  href="#"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <i className="bi bi-facebook"></i>
                </a>
                <a
                  href="#"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <i className="bi bi-instagram"></i>
                </a>
                <a
                  href="#"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <i className="bi bi-whatsapp"></i>
                </a>
                <a
                  href="#"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <i className="bi bi-linkedin"></i>
                </a>
                <a
                  href="#"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <i className="bi bi-x"></i>
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;
