import React, { useEffect, useState } from "react";
import { MapPin, Clock, DollarSign } from "lucide-react";
import { host, v } from "../../../config";
import FeaturedJob from "./FeaturedJob";
import Navbar from "../../CommonWidget/Navbar/Navbar";

const SeeJobs = () => {
  const [loading, setLoading] = useState(false);
  const [featuredJobs, setFeaturedJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [names, setNames] = useState([]);

  const getJobs = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${host}/api/${v}/visitor/jobs`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setFeaturedJobs(data.jobs);
      setFilteredJobs(data.jobs);
      setNames([...new Set(data.jobs.map((job) => job.name))]);
    } catch (error) {
      console.error("Error during fetch:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Navbar />
      {/* Hero Section */}
      <div className="relative w-full">
        <div className="h-[250px] md:h-[300px] w-full relative overflow-hidden">
          <img
            src="./bg.png"
            className="w-full h-full object-cover"
            alt="Background"
          />
          <div className="absolute inset-0 bg-black/40 flex flex-col justify-center items-center text-center p-4">
            <h1 className="text-2xl md:text-4xl font-bold text-white mb-4">
              Find Teaching Jobs in India
            </h1>
            <p className="text-sm md:text-lg text-white max-w-2xl">
              Search hundreds of teaching jobs abroad, including opportunities
              with international schools, government programs, universities and
              language colleges in INDIA.
            </p>
          </div>
        </div>
      </div>
      {/* Main Content */}
      <main className="flex-1 container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
          <h2 className="text-2xl font-bold text-gray-800">Featured Jobs</h2>
          <p className="text-gray-600 mt-2 md:mt-0">
            Found {filteredJobs.length} Jobs
          </p>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-gray-200 border-t-blue-600"></div>
          </div>
        ) : filteredJobs.length === 0 ? (
          <div className="flex justify-center items-center h-64">
            <p className="text-gray-500 text-lg">No Job Found!</p>
          </div>
        ) : (
          <div className="space-y-4">
            {filteredJobs.map((job, index) => (
              <FeaturedJob value={job} />
            ))}
          </div>
        )}
      </main>
      <footer className="bg-gray-900 text-white py-8">
        {/* Footer content */}
      </footer>
    </div>
  );
};

export default SeeJobs;
