import React, { useState } from "react";
import { Menu, X } from "lucide-react";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full">
      <nav className="relative bg-white">
        <div className="container mx-auto px-4">
          <div className="h-16 flex items-center justify-between">
            {/* Logo */}
            <img
              src="/logo.png"
              alt="Tutor Platter"
              className="h-12 object-contain"
            />

            {/* Desktop Menu */}
            <div className="hidden md:flex items-center space-x-8">
              <a href="/" className="text-gray-800 hover:text-gray-600">
                HOME
              </a>
              <a href="/seejobs" className="text-gray-800 hover:text-gray-600">
                FEATURED JOBS
              </a>
              <a href="/hireteachers" className="text-gray-800 hover:text-gray-600">
                HIRE TEACHERS
              </a>
              <a href="/login" className="text-gray-800 hover:text-gray-600">
                LOGIN
              </a>
            </div>

            {/* Mobile Menu Button */}
            <button
              className="md:hidden p-2 rounded-md hover:bg-gray-100"
              onClick={toggleMenu}
            >
              {isOpen ? (
                <X className="h-6 w-6 text-gray-600" />
              ) : (
                <Menu className="h-6 w-6 text-gray-600" />
              )}
            </button>
          </div>

          {/* Mobile Menu */}
          {isOpen && (
            <div className="md:hidden absolute top-16 left-0 right-0 bg-white border-b border-gray-200">
              <div className="flex flex-col space-y-4 px-4 py-6">
                <a
                  href="/"
                  className="text-gray-800 hover:text-gray-600 text-lg"
                  onClick={toggleMenu}
                >
                  HOME
                </a>
                <a
                  href="/seejobs"
                  className="text-gray-800 hover:text-gray-600 text-lg"
                  onClick={toggleMenu}
                >
                  FEATURED JOBS
                </a>
                <a
                  href="/hireteachers"
                  className="text-gray-800 hover:text-gray-600 text-lg"
                  onClick={toggleMenu}
                >
                  HIRE TEACHERS
                </a>
                <a
                  href="/login"
                  className="text-gray-800 hover:text-gray-600 text-lg"
                  onClick={toggleMenu}
                >
                  LOGIN
                </a>
              </div>
            </div>
          )}
        </div>
      </nav>
      {/* Green Bar */}
      <div className="h-8 bg-green-500 w-full" />
    </div>
  );
};